<template>
  <div class="table-wrapper">
    <div v-if="!emptyTable" :class="lessPadding ? 'custom-table-wrapper-skinny' : 'custom-table-wrapper'">
      <div class="header-container">
        <div class="filter-search-area">
          <Searchbar
            v-if="searchFunction && !hideSearch"
            :text="searchBarOptions"
            :search-input="bounceSearch" />
          <MultiFilterDropdown v-if="filterData"
                               :dropdown-options="filterData.dropdownOptions"
                               :placeholder="filterData.placeholder"
                               :single-selection="filterData.singleSelection"
                               :applied-filters="currentFilters"
                               @options="updateFilters"/>
          <MultiFilterDropdown v-if="filterDataSecondary"
                               :dropdown-options="filterDataSecondary.dropdownOptions"
                               :placeholder="filterDataSecondary.placeholder"
                               :single-selection="filterDataSecondary.singleSelection"
                               :applied-filters="currentFilters"
                               @options="updateFilters"/>
          <div class="Extra-dropdowns">
            <slot name="extra-dropdowns"/>
          </div>
          <div class="right-side-info">
            <slot name="right-side-info"/>
          </div>
        </div>
        <div v-if="currentFilters" class="filter-tags-wrapper">
          <div v-for="tag in currentFilters"
               :key="tag">
            <FilterTag :tag="tag"
                       :remove-tag="updateFilters" />
          </div>
        </div>
        <slot name="below-filters"/>
      </div>
      <table v-if="tableData && tableData.length > 0"
             :class="tableClass"
             class="custom-table">
        <thead>
          <tr class="head-custom-table">
            <td v-for="(value, keyName, index) in options.headings"
                :id="`${keyName}Head`"
                :key="index"
                class="cell-custom-table head">
              <div v-if="!options.sortable.includes(keyName)" class="normal-header">
                {{ value }}
                <slot :name="keyName + '-head'"></slot>
              </div>
              <div v-else class="sorting-icons" @click="checkSorting(keyName)">
                {{ value }}
                <slot :name="keyName + '-head'"></slot>
                <inline-svg
                  class="arrow"
                  :src="require('../../assets/icons/icn_sort.svg')"
                  :class="[
                    { 'up-arrow': (selectedSortingId.keyName === keyName) && selectedSortingId.ASC },
                    { 'down-arrow': (selectedSortingId.keyName === keyName) && !selectedSortingId.ASC },
                    { 'no-arrow': (selectedSortingId.keyName !== keyName) }
                  ]" />
              </div>
            </td>
          </tr>
        </thead>
        <tbody v-if="tableData">
          <tr v-for="rowData in tableData"
              :key="rowData.id"
              class="row-custom-table"
              :class="{'hover-reorder': reordering}"
              @mouseup="() => stopReorder(rowData)">
            <td v-for="(value, keyName, index) in options.headings" :key="index" class="cell-custom-table">
              <slot :name="keyName" :rowData="rowData"></slot>
            </td>
            <!--  todo improve global logic if CustomTable (make a wrapper for component for each different CustomTable -->
            <td v-if="currentRoute.name === 'MissionDetails'" class="cell-custom-table">
              <a
                :href="baseGameUrl + '/game/missie/' + rowData.missionId + '/slide/' + rowData.sortIndex + '?adminCode=DTT-MM-<3'"
                style="display: flex; padding-left: 16px;"
                target="_blank"
              >
                <InlineSvg
                  :src="require('../../assets/icons/icn_visibility.svg')"
                ></InlineSvg>
              </a>
            </td>
            <!--  todo improve global logic-->
            <td v-if="currentRoute.name === 'MediaQuiz'" class="cell-custom-table">
              <a
                v-if="rowData.isActivated == 1"
                :href="baseGameUrl + '/media-quiz/' + rowData.id + '?adminCode=DTT-MM-<3'"
                style="display: flex; padding-left: 16px; padding-right: 16px;"
                target="_blank"
              >
                <InlineSvg
                  :src="require('../../assets/icons/icn_visibility.svg')"
                ></InlineSvg>media
              </a>

              <div
                v-else
                class="disable-preview tooltip"
                style="display: flex; padding-left: 16px; padding-right: 16px;"
              >

                <span class="tooltiptext">{{ $t('MEDIAQUIZ.PREVIEW_TOOLTIP') }}</span>
                <InlineSvg
                  :src="require('../../assets/icons/icn_visibility.svg')"
                ></InlineSvg>
              </div>
            </td>
            <td v-if="onReorder !== null" class="cell-custom-table burger-icon-container" @mousedown="startReorder(rowData)">
              <InlineSvg
                :src="require('../../assets/icons/icn_burgermenu.svg')"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <BasicPagination v-if="paginationData"
                       :pagination-data="paginationData"
                       :go-to-page="changePage"
      />

    </div>
    <!-- Shown if the table is empty -->
    <div v-if="emptyTable && emptyStateData" class="empty-state">
      <img :src="emptyStateData.icon" class="empty-icon"/>
      <h1 class="empty-title"> {{ emptyStateData.title }}</h1>
      <p class="empty-text"> {{ emptyStateData.text }}</p>
      <BasicButton class="empty-button"
                   :button-text="emptyStateData.buttonText"
                   @click="emptyStateData.buttonAction"
      />
    </div>

    <div v-if="noSearchResults && noResultsData" class="empty-state">
      <object class="empty-icon" :data="noResultsData.icon" > </object>
      <h1 class="empty-title">{{ noResultsData.title }}</h1>
      <p class="empty-text">{{ noResultsData.text }}</p>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import Searchbar from '@/components/partials/search/Searchbar'
import BasicPagination from '@/components/partials/pagination/BasicPagination'
import MultiFilterDropdown from '@/components/elements/dropdown/MultiFilterDropdown'
import FilterTag from '@/components/partials/filter/FilterTag'
import BasicButton from '@/components/elements/basic/BasicButton'
import InlineSvg from 'vue-inline-svg'
import { useRoute } from 'vue-router'

export default {
  name: 'CustomTable',
  components: {
    Searchbar,
    BasicPagination,
    MultiFilterDropdown,
    FilterTag,
    BasicButton,
    InlineSvg
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    tableData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tableClass: {
      type: String,
      default: ''
    },
    searchBarOptions: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: { placeholder: '', searchId: 0 }
    },
    paginationData: {
      type: Object,
      default: undefined
    },
    searchFunction: {
      type: Function,
      default: undefined,
      note: 'function returning both search and page (either can be empty)'
    },
    hideSearch: {
      type: Boolean,
      default: false
    },
    minSearchTime: {
      type: Number,
      default: 0.5,
      note: 'number of seconds before sending off a search message'
    },
    minSearchCharacters: {
      type: Number,
      default: 3,
      note: 'number of characters required for a search to be accepted'
    },
    filterData: {
      type: Object,
      default: undefined
    },
    filterDataSecondary: {
      type: Object,
      default: undefined
    },
    emptyStateData: {
      type: Object,
      default: undefined
    },
    noResultsData: {
      type: Object,
      default: undefined
    },
    lessPadding: {
      type: Boolean,
      default: false
    },
    onReorder: {
      type: Function,
      default: null
    }
  },
  setup(props) {
    // define selected id
    const selectedSortingId = ref({ keyName: '', ASC: false })
    const lastSearch = ref('')
    const currentPage = ref(1)
    const currentFilters = ref([])
    const activeFilters = ref(lastSearch.value !== '' || currentFilters.value.length !== 0)
    const currentRoute = useRoute()
    const baseGameUrl = process.env.VUE_APP_ROOT_GAME

    //This stops the table flashing "empty" when search is removed.
    watch(() => props.tableData,
      (curr, prev) => {
        activeFilters.value = lastSearch.value !== '' || currentFilters.value.length !== 0
      })
    const emptyTable = computed(() => {
      return !props.tableData.length && !activeFilters.value
    })

    const noSearchResults = computed(() => {
      return !props.tableData.length && activeFilters.value
    })

    const checkSorting = (keyName) => {
      if (props.options.sortable.includes(keyName)) {
        if (selectedSortingId.value.keyName === keyName) {
          //flip if already selected
          selectedSortingId.value.ASC = !selectedSortingId.value.ASC
        } else {
          //don't flip, just choose
          selectedSortingId.value.keyName = keyName
          selectedSortingId.value.ASC = false
        }
        // send the option outside
        search(true)
      }
    }



    //last search timeout
    let timerId

    function bounceSearch(searchTerm) {
      clearTimeout(timerId)
      lastSearch.value = searchTerm
      timerId = setTimeout(() => search(true), props.minSearchTime * 1000)
    }


    function search(resetPage = false) {
      if (resetPage) {
        currentPage.value = 1
      }

      const payload = {
        searchQuery: lastSearch.value,
        page: currentPage.value,
        filters: currentFilters.value
      }

      if (selectedSortingId.value && selectedSortingId.value.keyName) {
        payload.sorting = selectedSortingId.value
      }
      if (props.searchFunction) {
        props.searchFunction(payload)
      }
    }

    search()


    function changePage(newPage) {
      currentPage.value = newPage
      search(false)
    }

    //filter options


    function updateFilters(tag) {
      if (tag.singleSelection) {
        currentFilters.value = currentFilters.value.filter(filter => filter.type !== tag.type || filter.id === tag.id)
      }
      const index = currentFilters.value.findIndex(filter => filter.id === tag.id && filter.type === tag.type)
      if (index === -1) {
        currentFilters.value.push(tag)
      } else {
        currentFilters.value.splice(index, 1)
      }
      search(true)
    }


    function startReorder(id) {
      selectedForReorder.value = id
    }

    function stopReorderDelayed() {
      setTimeout(() => { selectedForReorder.value = null }, 100)
    }

    onMounted(() => window.addEventListener('mouseup', stopReorderDelayed))
    onUnmounted(() => window.removeEventListener('mouseup', stopReorderDelayed))


    function stopReorder(data) {
      if (!reordering.value) {
        return
      }
      if (props.onReorder) {
        props.onReorder(selectedForReorder.value.id, data.sortIndex)
      }
      selectedForReorder.value = null
    }


    const selectedForReorder = ref(null)
    const reordering = computed(() => selectedForReorder.value !== null)

    return {
      checkSorting,
      selectedSortingId,

      emptyTable,
      noSearchResults,
      currentPage,
      bounceSearch,

      changePage,
      updateFilters,
      currentFilters,
      lastSearch,

      startReorder,
      stopReorder,
      selectedForReorder,
      reordering,

      baseGameUrl,
      currentRoute,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.custom-table-wrapper {
  //padding: 0 rem(30);
}

.custom-table-wrapper-skinny {
  margin: rem(-30);
  padding: rem(16) rem(30);
}

.header-container{
  display: flex;
  flex-direction: column;
}

.filter-search-area{
  display: flex;
  align-items: center;
  gap: rem(20);
  margin-bottom: rem(10);
}

.filter-tags-wrapper{
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: rem(10);
}

.right-side-info{
  text-align: right;
  align-self: flex-start;
  width: 100%;
  color: rgb(137,142,174);
}

/** Table - Shared Styling **/
.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 rem(10);

  #actionsHead {
    text-align: start;
    width: 0;
    white-space: nowrap;
    margin-right: rem(10);
  }
}

tr td:last-child {
    width: 1%;
}

.cell-custom-table {
  padding: rem(7) rem(5) rem(7) rem(15);
  width: fit-content;
}

/** **/

/** Table Row - Head **/
.head-custom-table {
  background-color: transparent;
  white-space: nowrap;
  .cell-custom-table {
    font-size: rem(16);
  }
}

/** **/

/** Table Row - Row **/
.row-custom-table {
  vertical-align: middle;
  min-height: rem(47);
  border-radius: rem(8);

  .cell-custom-table {
    background: white;

    &:nth-child(1) {
      @include border-radius-separate(rem(8), 0, 0, rem(8));
    }

    &:last-child {
      @include border-radius-separate(0, rem(8), rem(8), 0);
    }
  }
}

.hover-reorder {
  &:hover {
    & > td {
      border-top: 3px solid var(--blue_light);
    }
  }
}

.disable-preview {
  cursor: pointer;
  svg {
    opacity: 0.5;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: rem(140);
  background-color: white;
  text-align: center;
  border-radius: rem(6);
  padding: rem(5) 0;
  position: absolute;
  z-index: 1;
  right: 110%;
  transform: translateY(-45%);
  box-shadow: rem(5) rem(5) rem(15) rem(-3) rgba(0,0,0,0.45);

  /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
  opacity: 0;
  transition: opacity 0.5s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent white;
}

/** **/

.cell-custom-table > * {
  vertical-align: middle;
}

/** Custom styling **/
.action-button-group {
  display: flex;
  justify-content: flex-end;
}

.burger-icon-container {
  cursor: pointer;
  width: 50px !important;
}



.status-cell {
  display: flex;
  align-items: center;
}

.status-icon {
  margin-right: rem(5);
}

.arrow {
  width: rem(12);
  height: rem(12);
  transition: opacity .25s;
}

.sorting-icons {
  display: inline-block;
  //margin-left:rem(10);

  &:hover {
    cursor: pointer;
  }
  overflow: visible;
  :deep g {
    // Added the top and bottom classes onto the svg paths to make sure I can edit them.
    // If the SVG is ever replaced make sure to add these classes to keep this working.
    .top {
      opacity: 1;
    }
    .bottom {
      opacity: 1;
    }
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes reset {
  0% { opacity: 1; }
  100% { opacity: 1; }
}


.up-arrow{
  :deep g {
    .top {
      animation: fadeIn .25s forwards;
    }
    .bottom {
      animation: fadeOut .25s forwards;
    }
  }
}

.down-arrow{
  :deep g {
    .top {
      animation: fadeOut .25s forwards;
    }
    .bottom {
      animation: fadeIn .25s forwards;
    }
  }
}

.no-arrow{
  :deep g {
    .top {
      animation: reset .25s forwards;
    }
    .bottom {
      animation: reset .25s forwards;
    }
  }
}

/** Custom Classes **/
.new-requests-table {

}

.classes-table {
  font-size: rem(16);
  line-height: rem(19);

  #nameHead {
    width: 15%;
  }

  #educationTypeHead {
    width: 15%;
  }

  #groupHead {
    width: 10%;
  }

  #totalStudentsHead {
    width: 10%;
  }

  #createdAtHead {
    width: 10%;
  }

  #actionsHead {
    width: 0;
  }
}

.schools-table {
  font-size: rem(16);
  line-height: rem(19);
}

.missions-table{
  font-size: rem(16);
  line-height: rem(34);
}



.school-overview-table {
  #nameHead {
    width: 15%;
  }

  #brinHead {
    width: 5%;
  }

  #postalCodeHead {
    width: 5%;
  }

  #cityHead {
    width: 10%;
  }

  #provinceHead {
    width: 8%;
  }

  #createdAtHead {
    width: 10%;
  }

  #updatedAtHead {
    width: 10%;
  }

  #actionsHead {
    width: 0%;
  }
}

.teachers-table {
  #nameHead {
    width: 10%;
  }

  #emailHead {
    width: 5%;
  }

  #educationTypeHead {
    width: 15%;
  }

  #isApprovedHead {
    width: 5%;
  }

  #createdAtHead {
    width: 5%;
  }

  #lastLoginHead {
    width: 5%;
  }

  #actionsHead {
    width: rem(108);
  }
}

.linked-teachers {
  #nameHead {
    width: 90%;
  }

  #actionsHead {
    width: 0%;
  }
}

.linked-classes {
  #nameHead {
    width: 30%;
  }

  #groupHead {
    width: 15%;
  }

  #totalStudentsHead {
    width: 40%;
  }

  #actionsHead {
    width: 0%;
  }
}

.my-classes {
  #nameHead {
    width: 20%;
  }
  #teacherHead {
    width: 30%;
  }
  #groupHead {
    width: 20%;
  }
  #actionsHead {
    width: 15.6rem;
  }
}

.my-school {
  #emailHead {
    width: 30%;
  }
  #nameHead {
    width: 28%;
  }
  #dateHead {
    width: 19%;
  }
  #actionsHead {
    width: 15.6rem;
  }
}

.class-details-table {
  #nameHead {
    width: 90%;
  }

  #actionsHead {
    width: 10%;
  }
}

.tags-table{
  #nameHead {
    width: 90%;
  }

  #actionsHeader{
    width: 10%;
  }
}


.empty-state{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: rem(100) auto 0 auto;
  max-width: rem(480);
  text-align: center;

  .empty-icon{
    width: rem(128);
    height: rem(128);
    object-fit: contain;
    object-position: bottom;
  }

  .empty-title{
    margin-top: rem(20);
    margin-bottom: rem(17);
  }

  .empty-text{

  }

  .empty-button{
    margin-top: rem(30);
  }
}

/** **/
</style>
